<template>
  <div class="main-container">
    <h5 class="pageTitle">드론 타입 조회</h5>

    <mdb-container class="search-container">
      <mdb-row class="search-row">
        <mdb-col class="search-col">
          <select v-model="searchCriteria" class="search-select">
            <option value="model">모델(타입)</option>
            <!-- <option value="type">타입</option>
            <option value="name">타입이름</option> -->
            <option value="order">순서</option>
          </select>
          <input
            v-model="searchQuery"
            type="text"
            :placeholder="`검색할 내용을 입력하세요`"
            class="search-input"
            @input="filterResults"
          />
          <button class="btn-search" @click="filterResults">검색</button>
          <button class="btn-register" @click="goToRegister">등록</button>
        </mdb-col>
      </mdb-row>
    </mdb-container>  
  
    <table>
      <thead>
        <tr>
          <th>NO.</th>
          <th>모델(타입)</th>
          <!-- <th>타입</th>
          <th>타입이름</th> -->
          <th>순서</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="paginatedData.length === 0">
          <td colspan="5">데이터가 없습니다.</td>
        </tr>
        <tr
          v-for="(item, index) in paginatedData"
          :key="item.uuid"
          :class="{ 'alt-row': index % 2 === 1 }"
          @click="goToUpdate(item.uuid)"
          class="clickable-row"
        >
          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
          <td>{{ item.model }}</td>
          <!-- <td>{{ item.type }}</td>
          <td>{{ item.name }}</td> -->
          <td>{{ item.order }}</td>
        </tr>
      </tbody>
    </table>

    <!-- 페이지네이션 -->
    <div class="pagination">
      <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">
        Prev
      </button>
      <span>Page {{ currentPage }} of {{ pageCount }}</span>
      <button class="pagination-button" @click="nextPage" :disabled="currentPage === pageCount">
        Next
      </button>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { mdbContainer, mdbRow, mdbCol } from "mdbvue";

export default {
  name:"droneTypeRead",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
  },
  data() {
    return {
      results: [], // 원본 데이터
      filteredResults: [], // 필터링된 데이터
      searchCriteria: "model", // 초기 검색 기준 추가
      searchQuery: "", // 검색어 추가
      currentPage: 1,
      perPage: 10, // 페이지당 데이터 수
    };
  },
  computed: {
    paginatedData() {
      if (!this.filteredResults) return []; // filteredResults가 undefined일 경우 방지
      const start = (this.currentPage - 1) * this.perPage;
      return this.filteredResults.slice(start, start + this.perPage);
    },
    pageCount() {
      return Math.ceil((this.filteredResults?.length || 0) / this.perPage);
    },
  },
  methods: {
    async fetchResults() {
      const tokenData = JSON.parse(sessionStorage.getItem("token"));
      const token = tokenData?.access_token || "";

      try {
        const response = await axios.get("/master/drone-type", {
          headers: { Authorization: `Bearer ${token}` },
        });

        this.results = response.data.map((item, index) => ({
          no: index + 1,
          uuid: item.uuid,
          model: item.model,
          type: item.type,
          name: item.name,
          order: item.order,
        }));
        this.filteredResults = [...this.results]; // 초기 필터링 데이터 설정
      } catch (error) {
        console.error("데이터 조회 실패:", error);
      }
    },

    filterResults() {
      const query = this.searchQuery.trim().toLowerCase();
      if (!query) {
        this.filteredResults = [...this.results]; // 검색어 없으면 전체 데이터 표시
        return;
      }

      this.filteredResults = this.results.filter((item) => {
        let value = "";
        if (this.searchCriteria === "order") {
          value = item.order.toString(); // 숫자는 문자열 변환 후 검색
        } else {
          value = item[this.searchCriteria]?.toString().toLowerCase() || "";
        }
        return value.includes(query);
      });

      this.currentPage = 1; // 검색 시 페이지 초기화
    },

    prevPage() {
      if (this.currentPage > 1) this.currentPage--;
    },
    nextPage() {
      if (this.currentPage < this.pageCount) this.currentPage++;
    },

    goToRegister() {
      this.$router.push({ name: "master-droneTypeCreate" });
    },

    goToUpdate(uuid) {
      if (!uuid) {
        console.error("UUID가 전달되지 않았습니다.");
        return;
      }
      this.$router.push({ name: "master-droneTypeUpdateDelete", params: { id: uuid } });
    },
  },

  mounted() {
    this.fetchResults();
  },
};
</script>


<style scoped>
  .main-container {
    padding: 20px;
  }
  
  .pageTitle {
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .search-container {
    padding: 25px;
 
  }
  .search-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 오른쪽 정렬 */
  }
  
  .search-col {
    display: flex;
    align-items: center;
    gap: 10px; /* 각 요소 사이에 간격 추가 */
  }
  .search-select {
    height: 38px;
    border-radius: 3px;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }
  
  .search-input {
    height: 38px;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    border: 1px solid rgba(130, 128, 128, 0.26);
  }

  .btn-search {
    background-color: #e6f2ff;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-right: 20px; /* 검색 버튼과 등록 버튼 사이의 여백 */
    /*cursor: pointer;
     transition: background-color 0.3s ease; */
  }
  .btn-register {
    background-color: #ffc107;
    padding: 11px 12px;
    font-size: 14px;
    border: 0;
    width: 67px;
    height: 38px;
    line-height: 1rem;
    color: #3e3e3e;
    margin-left: auto; /* 등록 버튼을 오른쪽 끝으로 정렬 */
    align-self: flex-end; /* 등록 버튼을 아래쪽으로 정렬 */
    }
  
    table {
    width: 80%;
    border-collapse: collapse;
    margin: auto;
    text-align: center;
  }
  
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #e3e4eb;
    height: 55px;  
  }
  
  tr {
    height: 50px;
    cursor: pointer;
  }
  .alt-row {
    background-color: #f9f9f9;
  }
  .clickable-row {
    cursor: pointer;
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-button {
    padding: 5px 10px;
    margin: 0 5px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
 
  </style>
  